var isGTMHidden = document.querySelector('meta[name="hideGTM"]')?.content;

if (isGTMHidden === "false") {
  if (window.dataLayer) {
    window.dataLayer.push({
      event: "page_viewed",
      page_title: "Rental tracker",
      page_location: window.location.pathname,
    });
  }

  var rentalLinks = document.querySelectorAll(".rental-tracker__item");
  rentalLinks?.forEach((link) => {
    link?.addEventListener("click", function (e) {
      var linkName = e.target
        .closest(".rental-tracker__item")
        .querySelector(".rental-tracker__item-title").innerText;
      var linkDestination = new URL(
        e.target.closest(".rental-tracker__item").href
      ).pathname;

      if (window.dataLayer) {
        window.dataLayer.push({
          event: "cta_clicked",
          link_name: linkName,
          link_type: "anchor",
          link_location: "body",
          link_destination: linkDestination,
        });
      }
    });
  });
}