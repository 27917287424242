import { useIntl } from 'react-intl';
import {
    SET_CURRENT_OFFSET_VALUE,
    SET_TIMEZONE_ID,
} from '../aem-core-components/actions/constants';
import { useCartState } from '../contexts/cart';
import {
    getAVSValidationAPI,
    getTimezoneAPI
} from '../components/checkoutv2/checkoutAndOrderSummary/api/getCheckoutAPIs';
import { getTimeFromCity } from '../components/global/utils/commonUtils';
import { ENV_CONFIG } from '../constants/envConfig';

const useRoundTrip = () => {
    const [{ cart }, dispatch] = useCartState();
    const intl = useIntl();
    const validStateList = ENV_CONFIG.VALID_STATE_LIST;

    const isLargeEquipment = () => {
        let isEquipmentLarge = false;
        cart?.availableCartItems?.every(item => {
            if (item?.product?.isOverSize == 'Yes' || item?.product?.isOverWeight == 'Yes') {
                isEquipmentLarge = true;
            }
        });
        return isEquipmentLarge;
    };

    const getAvsResponse = async (addressObj, isAddrListReq = false) => {
        const avsResp = await getAVSValidationAPI(addressObj);
        const { data, avsSuggestedResponse, error } = avsResp;
        let isValid = false;
        let option = data ? data?.data?.addressInfo?.addrResult : avsSuggestedResponse?.data?.addressInfo?.addrResult;
        let latFromAVS = '';
        let longFromAVS = '';
        let addressFromAvs = '';
        let addrResult = 0;
        let avsError = '';
        switch (option) {
            case '0':
                isValid = true;
                addressFromAvs = data?.data?.addrList[0];
                latFromAVS = data?.data?.addressInfo?.lat;
                longFromAVS = data?.data?.addressInfo?.long;
                break;
            case '1':
                isValid = true;
                addressFromAvs = data?.data?.addrList[0];
                latFromAVS = data?.data?.addressInfo?.lat;
                longFromAVS = data?.data?.addressInfo?.long;
                break;
            case '2':
                avsError = data?.data?.addressInfo?.addMessage;
                dispatch({ type: 'endLoading' });
                break;
            case '3':
                addrResult = 3;
                addressFromAvs = isAddrListReq ? data?.data?.addrList : '';
                dispatch({ type: 'endLoading' });
                break;
            case '4':
                avsError = intl.formatMessage({ id: 'order-details:avs-error-label' });
                dispatch({ type: 'endLoading' });
                break;
            default:
                avsError = intl.formatMessage({ id: 'order-details:avs-error-label' });
                dispatch({ type: 'endLoading' });
        }
        return { isValid, latFromAVS, longFromAVS, addressFromAvs, addrResult, avsError, error };
    };

    async function fetchTimeZoneAPI(address) {
        let offset = '';
        const responseData = await getTimezoneAPI(address);
        if (!responseData?.error) {
            offset = getTimeFromCity(responseData?.data?.data?.timeZoneId);
            dispatch({
                type: SET_TIMEZONE_ID,
                timeZoneID: responseData?.data?.data?.timeZoneId
            });
            dispatch({
                type: SET_CURRENT_OFFSET_VALUE,
                currentOffset: offset
            });
        } else {
            dispatch({ type: 'endLoading' });
        }
        return { offset, timeZoneID: responseData?.data?.data?.timeZoneId, error: responseData?.error };
    }

    const getCountryLabel = () => {
        return localStorage.getItem('companyID') == 2
            ? intl.formatMessage({ id: 'common:country-ca' })
            : intl.formatMessage({ id: 'common:country-usa' });
    };

    const computeAvsAddressLine1 = addressFromAvs => {
        let location =
            `${addressFromAvs.address1 ? addressFromAvs.address1 + ', ' : ''}` +
            `${addressFromAvs.city ? addressFromAvs.city + ', ' : ''}` +
            `${addressFromAvs.state ? addressFromAvs.state : ''}` +
            `, ${getCountryLabel()}`;
        return location;
    };

    const isCanadaStateValid = state => {
        if (parseInt(localStorage.getItem('companyID')) == 2) {
            if (validStateList?.includes(state)) {
                return true;
            } else {
                return false;
            }
        }
        return true;
    };

    return {
        fetchTimeZoneAPI,
        isCanadaStateValid,
        getAvsResponse,
        computeAvsAddressLine1,
        isLargeEquipment
    };
};

export default useRoundTrip;
